<template>
  <div class="timer">
    <div class="label">
      <div v-if="failedOrder">
        {{ $t('new_checkout.timer_notifications.order_not_exist') }}
      </div>
      <div v-else-if="!timeIsOver && (status.value === 'partially_paid' || status.value === 'not_paid')">
        <div class="label">
          {{ $t('new_checkout.timer_notifications.remaining_time') }}
        </div>
        <div class="time">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5V9C7 9.17943 7.09614 9.3451 7.25193 9.43412L10.7519 11.4341C10.9917 11.5711 11.2971 11.4878 11.4341 11.2481C11.5711 11.0083 11.4878 10.7029 11.2481 10.5659L8 8.70984V3.5Z" fill="var(--bg-background-primary-purple)"/>
          </svg>
          {{ timer }}</div>
      </div>
      <div v-else-if="status.value === 'overpaid' || status.value === 'fully_paid'">
        {{ $t('new_checkout.timer_notifications.completed') }}
      </div>
      <div v-else-if="timeIsOver">
        {{ $t('new_checkout.timer_notifications.expired') }}
      </div>
    </div>
    <div>
      <progress-bar
        :progress-length="secondsFromStartToExpire"
        :passed-progress="secondsFromStartToNow"
        :success="status.value === 'overpaid' || status.value === 'fully_paid'"
        :danger="timeIsOver"
        :failed-order="failedOrder"
      />
    </div>
  </div>
</template>

<script>
import { checkoutOrderData } from "@/entries/checkout/mixins/checkout-order-data";
import moment from "moment";
import { ORDER_STATUS_ENUM } from "../../../common/constants/orders-status-enum";

export default {
  name: "Timer",
  components: {
    ProgressBar: () => import(/* webpackChunkName: "/entries/checkout/components/Progress" */ '@/entries/checkout/components/Progress'),
  },
  mixins: [ checkoutOrderData ],
  data() {
    return {
      time: new Date().toJSON(),
      timerId: 0,
    }
  },
  watch: {
    secondsFromNowToExpire: {
      handler(v) {
        if (v === 0) {
          this.$emit('update:time-is-over', true)
        }
      },
      immediate: true
    }
  },
  props: {
    timeIsOver: {
      type: Boolean,
      default: false
    },
    status: {
      type: Object,
      default: () => ({})
    },
    failedOrder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    secondsFromStartToExpire() { //общее число секунд на оплату ордера
      const createdAt = moment(this.orderCreatedAt);
      const expiresAt = moment(this.orderExpiresAt);

      const diff = expiresAt.diff(createdAt, 'seconds');

      if (diff <= 0) {
        return 0;
      }

      return diff;
    },
    secondsFromStartToNow() { //прошеднее число секунд с момента создания ордера
      const createdAt = moment(this.orderCreatedAt);
      const now = moment(this.time);

      const diff = now.diff(createdAt, 'seconds');

      if (diff <= 0) {
        return 0;
      }

      return diff;
    },
    secondsFromNowToExpire() { //оставшееся число секунд до момента истечения ордера
      const now = moment(this.time);
      const expiresAt = this.processed ? moment(this.time) : moment(this.orderExpiresAt);

      const diff = expiresAt.diff(now, 'seconds');

      if (diff <= 0) {
        return 0;
      }

      return diff;
    },
    timer({ secondsFromNowToExpire }) {
      const sec_num = parseInt(secondsFromNowToExpire || 0, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours} : ${minutes} : ${seconds}`
    },

    textStep() {
      if (this.orderStatus === ORDER_STATUS_ENUM.EXPIRED) {
        return this.$t('checkout.expired_timer');
      }

      if (this.orderStatus === ORDER_STATUS_ENUM.PROCESSED) {
        return this.$t('checkout.paid_timer');
      }

      return this.$t('checkout.before_the_expiration_of_the_order');
    },

    processed() {
      return this.orderStatus === ORDER_STATUS_ENUM.PROCESSED || this.orderStatus === ORDER_STATUS_ENUM.OVERPAID;
    }
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.stopTimer();

      this.timerId = setInterval(() => {
        this.time = new Date().toJSON();
      }, 1000);
    },

    stopTimer() {
      clearInterval(this.timerId);
    },
  },
}
</script>

<style lang="scss">
  .timer {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--border-border-neutral-variant);
    background: var(--bg-background-disabled);
    .label {
      color: var(--text-color-text-secondary, #8B81A0);
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: 500;
    }
    .time {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--text-text-primary, #000);
      font-family: 'Gilroy';
      font-size: 20px;
      font-weight: 600;
    }
  }
</style>
